import Lottie from "lottie-react";
import logoAnimationData from "./assets/logo-animation.json";

function App() {
  return (
    <>
      <div className="absolute inset-0 flex items-center justify-center">
        <Lottie animationData={logoAnimationData} loop autoplay />
      </div>
    </>
  );
}

export default App;
