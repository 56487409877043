import { StrictMode } from "react";
import { createRoot } from "react-dom/client";
import App from "./App.tsx";
import "./index.css";

// Render the app
const rootElement = document.getElementById("root");
if (!rootElement) {
  throw new Error("Root element could not be found");
}

createRoot(rootElement).render(
  <StrictMode>
    <App />
  </StrictMode>,
);
